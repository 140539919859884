import store from '@/store'
import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {RequestsState} from '@/domain/model/types';
import axios from '@/plugins/axios';
import {applicationStore} from '@/store/modules/application';
import {auth} from '@/plugins/firebase.init';
import {profileStore} from '@/store/modules/profile';
import {equalNow} from '@/utils/helpers';
import rfdc from 'rfdc';
import {businessAppointment, businessAppointments, chat, chats} from '@/data/firebase';
import {getDoc, onSnapshot, orderBy, Query, query, startAfter, updateDoc, where} from 'firebase/firestore';
import deleteProperty = Reflect.deleteProperty;

const clone = rfdc({proto: true})

const defaultState: RequestsState = {
  inbox: [],
  rejected: [],
  rejectedCountPersonal: 0,
  rejectedCountOther: 0,
  inboxCountPersonal: 0,
  inboxCountAll: 0,
  appointments: [],
  appointmentsCountPersonal: 0,
  appointmentsCountBusiness: 0,
  appointmentsCountAll: 0,
  selectedAppointment: null,
  appoints: {},
  selectedRequest: null,
  requestFullPath: '',
  requestUnread: []
};

const compareFn = (a, b) => {
  const atime = a.updatedDate && a.updatedDate.toMillis();
  const btime = b.updatedDate && b.updatedDate.toMillis();
  return atime === btime ? 0 : atime > btime ? -1 : 1;
};

@Module({dynamic: true, name: 'requests-store', store})
export default class RequestsStore extends VuexModule {
  private inbox: any[] = clone(defaultState.inbox)
  private rejected: any[] = clone(defaultState.rejected)
  private rejectedCountPersonal: number = clone(defaultState.rejectedCountPersonal)
  private rejectedCountOther: number = clone(defaultState.rejectedCountOther)
  private inboxCountPersonal: number = clone(defaultState.inboxCountPersonal)
  private inboxCountAll: number = clone(defaultState.inboxCountAll)
  private appointments: any[] = clone(defaultState.appointments)
  private appointmentsCountPersonal: number = clone(defaultState.appointmentsCountPersonal)
  private appointmentsCountBusiness: number = clone(defaultState.appointmentsCountBusiness)
  private selectedAppointment: any = clone(defaultState.selectedAppointment)
  private appoints: any = clone(defaultState.appoints)
  private selectedRequest: any = clone(defaultState.selectedRequest)
  private requestFullPath?: string = clone(defaultState.requestFullPath)
  private requestUnread: any[] = clone(defaultState.requestUnread)

  get getInbox() {
    return this.inbox
  }

  get getNewInbox() {
    const userId = profileStore.t2bUser?.id
    if (!userId) {
      return 0
    }
    return this.inbox.reduce((count, item) => item.unread && item.unread[userId] > 0 ? count + 1 : count, 0)
  }

  get showInboxBadge() {
    return this.getNewInbox > 0
  }

  get getInboxUrgent() {
    return this.inbox.filter((item) => item.case.priority === 1)
  }

  get getInboxNormal() {
    return this.inbox.filter((item) => item.case.priority === 2)
  }

  get getInboxLow() {
    return this.inbox.filter((item) => item.case.priority === 3)
  }

  get getRejected() {
    return this.rejected
  }

  get getRejectedUrgent() {
    return this.rejected.filter((item) => item.case.priority === 1)
  }

  get getRejectedNormal() {
    return this.rejected.filter((item) => item.case.priority === 2)
  }

  get getRejectedLow() {
    return this.rejected.filter((item) => item.case.priority === 3)
  }

  get getInboxCountPersonal() {
    return this.inboxCountPersonal
  }

  get getInboxCountAll() {
    return this.inboxCountAll
  }

  get getInboxCount() {
    return this.inboxCountPersonal + this.inboxCountAll
  }

  get getRejectedCount() {
    return this.rejectedCountPersonal + this.rejectedCountOther
  }

  get getRejectedCountPersonal() {
    return this.rejectedCountPersonal
  }

  get getRejectedCountOther() {
    return this.rejectedCountOther
  }

  get getAppointments() {
    return this.appointments
  }

  get personalAppoints() {
    return [...this.appointsTodayPersonal, ...this.appointsUpcomingPersonal]
  }

  get businessAppoints() {
    return [...this.appointsTodayBusiness, ...this.appointsUpcomingBusiness]
  }

  get appointsTodayPersonal() {
    const userId: any = profileStore.t2bUser?.id
    return this.appointments.filter((item) => item.memberIDs.includes(userId)
      && !item.canceled && !item.conducted && equalNow(item.startDate.toDate()))
  }

  get appointsTodayBusiness() {
    const userId: any = profileStore.t2bUser?.id
    return this.appointments.filter((item) => !item.memberIDs.includes(userId)
      && !item.canceled && !item.conducted && equalNow(item.startDate.toDate()))
  }

  get appointsUpcomingPersonal() {
    const userId: any = profileStore.t2bUser?.id
    return this.appointments.filter((item) => item.memberIDs.includes(userId)
      && !item.canceled && !item.conducted && !equalNow(item.startDate.toDate()))
  }

  get appointsUpcomingBusiness() {
    const userId: any = profileStore.t2bUser?.id
    return this.appointments.filter((item) => !item.memberIDs.includes(userId)
      && !item.canceled && !item.conducted && !equalNow(item.startDate.toDate()))
  }

  get appointsCanceledPersonal() {
    const userId: any = profileStore.t2bUser?.id
    return this.appointments.filter((item) => item.memberIDs.includes(userId) && item.canceled)
  }

  get appointsCanceledBusiness() {
    const userId: any = profileStore.t2bUser?.id
    return this.appointments.filter((item) => !item.memberIDs.includes(userId) && item.canceled)
  }

  get appointsConductedPersonal() {
    const userId: any = profileStore.t2bUser?.id
    return this.appointments.filter((item) => item.memberIDs.includes(userId) && item.conducted)
  }

  get appointsConductedBusiness() {
    const userId: any = profileStore.t2bUser?.id
    return this.appointments.filter((item) => !item.memberIDs.includes(userId) && item.conducted)
  }

  get getAppointmentsCount() {
    return this.appointmentsCountPersonal + this.appointmentsCountBusiness
  }

  get getAppointmentsCountPersonal() {
    return this.appointmentsCountPersonal
  }

  get getAppointmentsCountBusiness() {
    return this.appointmentsCountBusiness
  }

  get getSelectedAppointment() {
    return this.selectedAppointment
  }

  get appointsCountPersonal() {
    return this.appointsTodayPersonal.length + this.appointsUpcomingPersonal.length
  }

  get appointsCountBusiness() {
    return this.appointsTodayBusiness.length + this.appointsUpcomingBusiness.length
  }

  get appointsCount() {
    return this.appointsCountPersonal + this.appointsCountBusiness
  }

  get currentRequest() {
    return this.selectedRequest
  }

  get lastVisitedRequest() {
    return this.requestFullPath
  }

  get getRequestUnread(): any[] {
    return this.requestUnread
  }

  @Mutation
  public putAppointment(appoint) {
    this.appointments.push(appoint);
  }

  @Mutation
  public updateAppointment({appoint, oldAppoint}) {
    this.appointments.splice(this.appointments.indexOf(oldAppoint), 1, appoint);
  }

  @Mutation
  public removeAppointment(appoint) {
    this.appointments.splice(this.appointments.indexOf(appoint), 1);
  }

  @Mutation
  public incrementAppointmentsPersonal() {
    this.appointmentsCountPersonal += 1
  }

  @Mutation
  public decrementAppointmentsPersonal() {
    this.appointmentsCountPersonal -= 1
  }

  @Mutation
  public incrementAppointmentsAll() {
    this.appointmentsCountBusiness += 1
  }

  @Mutation
  public decrementAppointmentsAll() {
    this.appointmentsCountBusiness -= 1
  }

  @Mutation
  public setSelectedAppointment(appoint) {
    this.selectedAppointment = appoint;
  }

  @Mutation
  public setSelectedRequest(request: any) {
    this.selectedRequest = request
  }

  @Mutation
  public lastRequestRoute(fullPath: string) {
    this.requestFullPath = fullPath
  }

  @Action
  public async cancelAppointment(appointmentId) {
    const userId = profileStore.t2bUser?.id;
    const businessId = applicationStore.businessId;
    if (!userId || !businessId) {
      return;
    }
    const currentUser = auth.currentUser;
    try {
      const token = await currentUser?.getIdToken(false);
      await axios.patch(`/businesses/${businessId}/appointments/${appointmentId}/cancel`, null, {
        headers: {Authorization: `Bearer ${token}`}
      });
    } catch (err) {
      console.error(err)
    }
  }

  @Action
  public async rescheduleAppointment({appointmentId, from, to}) {
    const userId = profileStore.t2bUser?.id;
    const businessId = applicationStore.businessId;
    if (!userId || !businessId) {
      return
    }
    const currentUser = auth.currentUser;
    try {
      const token = await currentUser?.getIdToken(false);
      await axios.patch(`/businesses/${businessId}/appointments/${appointmentId}`, {
        startDate: from,
        endDate: to,
      }, {
        headers: {Authorization: `Bearer ${token}`}
      })
    } catch (err) {
      console.error(err)
    }
  }

  @Action
  public async scheduleAppointment({customerId, associateId, startDate, endDate, remind, comment}) {
    const businessId = applicationStore.businessId;
    if (!businessId) {
      return
    }
    const currentUser = auth.currentUser;
    try {
      const token = await currentUser?.getIdToken(false);
      await axios.post(`/businesses/${businessId}/appointments`, {
        customerId, associateId, startDate, endDate, remind, comment
      }, {
        headers: {Authorization: `Bearer ${token}`}
      })
    } catch (err) {
      console.error(err)
    }
  }

  @Action
  public loadAppointments() {
    const userId = profileStore.t2bUser?.id
    const businessId = applicationStore.businessId;
    if (!userId || !businessId) {
      return
    }
    let appointsQuery: Query = businessAppointments(businessId);
    if (!applicationStore.isAdmin) {
      appointsQuery = query(appointsQuery, where('memberIDs', 'array-contains', userId))
    }
    appointsQuery = query(appointsQuery, orderBy('startDate', 'asc'))
    if (this.appointments.length) {
      const date = this.appointments[this.appointments.length - 1].startDate;
      appointsQuery = query(appointsQuery, startAfter(date))
    }
    return onSnapshot(appointsQuery, (snapshot) => {
      snapshot.docChanges().forEach((docChange) => {
        const appoint = docChange.doc.data();
        appoint.id = docChange.doc.id;
        switch (docChange.type) {
          case 'added':
            if (this.appointments.findIndex((item) => item.id === appoint.id) === -1) {
              this.putAppointment(appoint);
              if (appoint.memberIDs.includes(userId)) {
                this.incrementAppointmentsPersonal()
              } else {
                this.incrementAppointmentsAll()
              }
            }
            break;
          case 'modified': {
            const modified = this.appointments.find((item) => item.id === appoint.id);
            this.updateAppointment({appoint, oldAppoint: modified});
            break;
          }
          case 'removed': {
            const removed = this.appointments.find((item) => !!item && item.id === appoint.id);
            if (!!removed) {
              this.removeAppointment(removed);
              if (appoint.memberIDs.includes(userId)) {
                this.decrementAppointmentsPersonal()
              } else {
                this.decrementAppointmentsAll()
              }
            }
            break;
          }
          default:
        }
      });
    });
  }

  @Action
  public loadInbox() {
    const userId = profileStore.t2bUser?.id;
    const businessId = applicationStore.businessId;
    if (!userId || !businessId) {
      return
    }
    let requestsQuery = query(chats,
      where('business.id', '==', businessId),
      where('case.status', '==', 1))
    if (!applicationStore.isAdmin) {
      requestsQuery = query(requestsQuery, where('memberIDs', 'array-contains', userId))
    }
    requestsQuery = query(requestsQuery, orderBy('updatedDate', 'desc'))
    if (this.inbox.length) {
      const updatedDate = this.inbox[0].updatedDate;
      requestsQuery = query(requestsQuery, startAfter(updatedDate))
    }
    return onSnapshot(requestsQuery, (snapshot) => {
      snapshot.docChanges().forEach((docChange) => {
        const request = docChange.doc.data();
        request.id = docChange.doc.id;
        switch (docChange.type) {
          case 'added':
            if (this.inbox.findIndex((item) => item.id === request.id) === -1) {
              this.putInbox(request);
              if (request.memberIDs.includes(userId)) {
                if (!!request.unread) {
                  this.addUnread({id : request.id, unread: request.unread[userId]})
                }
                this.incrementInboxPersonal();
              } else {
                this.incrementInboxAll();
              }
            }
            break;
          case 'modified':
            const modified = this.inbox.find((item) => !!item && item.id === request.id);
            if (request.memberIDs.includes(userId)) {
              if (!!request.unread) {
                this.updateUnread({id: request.id, unread: request.unread[userId]})
              }
            }
            this.updateInbox({request, oldRequest: modified});
            break;
          case 'removed':
            const removed = this.inbox.find((item) => !!item && item.id === request.id);
            if (!!removed) {
              this.removeInbox(removed);
              if (request.memberIDs.includes(userId)) {
                this.removeUnread(request.id)
                this.decrementInboxPersonal();
              } else {
                this.decrementInboxAll();
              }
            }
            break;
          default:
        }
      });
      this.inbox.sort(compareFn)
    });
  }

  @Action
  public loadRejected() {
    const userId = profileStore.t2bUser?.id;
    const businessId = applicationStore.businessId;
    if (!userId || !businessId) {
      return
    }
    this.resetRejected();
    let requestsQuery = query(chats,
      where('business.id', '==', businessId),
      where('case.status', '==', 3))
    if (!applicationStore.isAdmin) {
      requestsQuery = query(requestsQuery, where('memberIDs', 'array-contains', userId))
    }
    requestsQuery = query(requestsQuery, orderBy('updatedDate', 'desc'))
    return onSnapshot(requestsQuery, (snapshot) => {
      snapshot.docChanges().forEach((docChange) => {
        const request = docChange.doc.data();
        request.id = docChange.doc.id;
        switch (docChange.type) {
          case 'added':
            if (this.rejected.findIndex((item) => item.id === request.id) === -1) {
              this.putRejected(request);
              if (request.memberIDs.includes(userId)) {
                this.incrementRejectedPersonal();
              } else {
                this.incrementRejectedOther();
              }
            }
            break;
          case 'modified':
            const modified = this.rejected.find((item) => item.id === request.id);
            this.updateRejected({request, oldRequest: modified});
            break;
          case 'removed':
            const removed = this.rejected.find((item) => item.id === request.id);
            if (removed) {
              this.removeRejected(removed);
              if (request.memberIDs.includes(userId)) {
                this.decrementRejectedPersonal();
              } else {
                this.decrementRejectedOther();
              }
            }
            break;
          default:
        }
      });
      this.rejected.sort(compareFn)
    });
  }

  @Action
  public async changeRequestPriority({id, priority}) {
    if (!id) {
      return;
    }
    try {
      await updateDoc(chat(id), 'case.priority', priority);
    } catch (err) {
      console.error(err)
    }
  }

  @Action
  public loadAppointment({appointId, subtype}) {
    console.log(`loadAppointment => appointId=${appointId}, subtype=${subtype}`)
    if (appointId) {
      this.appoints[subtype] = this.appointments.find((item) => item.id === appointId)
    }
    this.setSelectedAppointment(this.appoints[subtype])
  }

  @Action
  public async loadAppointmentById(appointId) {
    const appointExist = this.appointments.find((item) => item.id === appointId);
    if (appointExist) {
      this.setSelectedAppointment(appointExist);
      return;
    }
    const businessId = applicationStore.businessId;
    console.log('loadAppointmentById', businessId, appointId);
    try {
      const snapshot = await getDoc(businessAppointment(businessId!, appointId));
      const appoint = snapshot.data();
      this.setSelectedAppointment(appoint)
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public findAppointmentIdForType(subtype) {
    let appoint = this.appoints[subtype];
    if (!appoint) {
      appoint = subtype === 'personal' ? this.personalAppoints[0] : this.businessAppoints[0]
      this.appoints[subtype] = appoint
    }
    return appoint ? appoint.id : null
  }

  @Action
  public findRequestIdForType({type, subtype}) {
    const userId = profileStore.t2bUser?.id;
    const key = `${type}:${subtype}`;
    let requestId = this.context.rootGetters.cachedLastTextSessions[key];
    if (!requestId) {
      const personal = subtype === 'personal';
      requestId = this.inbox.find((item) => item.memberIDs.includes(userId) === personal)?.id
      this.context.rootGetters.cachedLastTextSessions[key] = requestId
    }
    return requestId || ''
  }

  @Action
  public async checkRequestById(chatId: string) {
    if (!chatId) {
      return false
    }
    if (this.inbox.some((item) => item.id === chatId)
      || this.rejected.some((item) => item.id === chatId)) {
      return true
    }
    try {
      const snapshot = await getDoc(chat(chatId))
      if (snapshot.exists()) {
        const req = snapshot.data()
        return [1, 3].includes(req?.case?.status)
      }
    } catch (e) {
      console.log(e);
    }
    return false
  }

  @Action
  public clearRequestIdForType(typeKey: string) {
    deleteProperty(this.context.rootGetters.cachedLastTextSessions, typeKey)
  }

  @Action
  public clearState() {
    this.resetRequestsState()
  }

  @Mutation
  private incrementInboxPersonal() {
    this.inboxCountPersonal += 1
  }

  @Mutation
  private decrementInboxPersonal() {
    this.inboxCountPersonal -= 1
  }

  @Mutation
  private incrementInboxAll() {
    this.inboxCountAll += 1
  }

  @Mutation
  private decrementInboxAll() {
    this.inboxCountAll -= 1
  }

  @Mutation
  private putInbox(request) {
    this.inbox.push(request);
  }

  @Mutation
  private updateInbox({request, oldRequest}) {
    this.inbox.splice(this.inbox.indexOf(oldRequest), 1, request);
  }

  @Mutation
  private removeInbox(request) {
    this.inbox.splice(this.inbox.indexOf(request), 1);
  }

  @Mutation
  private resetRejected() {
    this.rejected = [];
  }

  @Mutation
  private putRejected(request) {
    if (this.rejected.findIndex((item) => item.id === request.id) === -1) {
      this.rejected.push(request);
    }
  }

  @Mutation
  private updateRejected({request, oldRequest}) {
    this.rejected.splice(this.rejected.indexOf(oldRequest), 1, request);
  }

  @Mutation
  private removeRejected(request) {
    this.rejected.splice(this.rejected.indexOf(request), 1);
  }

  @Mutation
  private incrementRejectedPersonal() {
    this.rejectedCountPersonal += 1
  }

  @Mutation
  private decrementRejectedPersonal() {
    this.rejectedCountPersonal -= 1
  }

  @Mutation
  private incrementRejectedOther() {
    this.rejectedCountOther += 1
  }

  @Mutation
  private decrementRejectedOther() {
    this.rejectedCountOther -= 1
  }

  @Mutation
  private resetRequestsState() {
    Object.keys(defaultState).forEach((key) => {
      this[key] = clone(defaultState)[key]
    })
  }

  @Mutation
  private addUnread({id, unread}) {
    if (this.requestUnread.findIndex((item) => item.id === id) === -1) {
      this.requestUnread.push({id, unread})
    }
  }

  @Mutation
  private updateUnread({id, unread}) {
    this.requestUnread.splice(this.requestUnread.findIndex((item) => item.id === id), 1, {id, unread})
  }

  @Mutation
  private removeUnread(id) {
    this.requestUnread.splice(this.requestUnread.findIndex((item) => item.id === id), 1)
  }
}

export const requestsStore = getModule(RequestsStore)
