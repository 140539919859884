




import {Component} from 'vue-property-decorator';
import BaseNavigationPage from '@/components/BaseNavigationPage.vue';
import {applicationStore} from '@/store/modules/application';
import {requestsStore} from '@/store/modules/requests/RequestsStore';
import {mixins} from "vue-class-component";
import Navigation from "@/mixins/Navigation";

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave'
])

@Component({
  name: 'requests',
  components: {BaseNavigationPage}
})
export default class Requests extends mixins(Navigation){

  get navItemInbox() {
    const {getInboxCount, getInboxCountPersonal, getInboxCountAll, showInboxBadge} = requestsStore;
    const query = this.$route.query;
    if (applicationStore.isAdmin) {
      return {
        title: 'Inbox',
        type: 'inbox',
        name: 'inbox',
        counter: getInboxCount,
        group: true,
        expanded: true,
        children: [
          {
            title: 'All',
            params: {chatId: ''},
            query: {type: 'inbox', subtype: 'all'},
            type: 'inbox',
            name: 'inbox',
            counter: getInboxCountAll,
            selected: query.type === 'inbox' && query.subtype === 'all'
          },
          {
            title: 'Mine',
            params: {chatId: ''},
            query: {type: 'inbox', subtype: 'personal'},
            type: 'inbox',
            name: 'inbox',
            counter: getInboxCountPersonal,
            innerBadge: showInboxBadge,
            selected: query.type === 'inbox' && query.subtype === 'personal'
          }
        ]
      }
    }
    return {
      title: 'Inbox',
      type: 'inbox',
      name: 'inbox',
      params: {chatId: ''},
      query: {type: 'inbox', subtype: 'personal'},
      counter: getInboxCount,
      innerBadge: showInboxBadge,
      selected: query.type === 'inbox' && query.subtype === 'personal'
    }
  }

  get navItemRejected() {
    const {getRejectedCount, getRejectedCountPersonal, getRejectedCountOther} = requestsStore;
    const query = this.$route.query;
    if (applicationStore.isAdmin) {
      return {
        title: 'Rejected',
        type: 'rejected',
        name: 'rejected',
        counter: getRejectedCount,
        group: true,
        expanded: true,
        children: [
          {
            title: 'All',
            params: {chatId: ''},
            query: {type: 'rejected', subtype: 'all'},
            type: 'rejected',
            name: 'rejected',
            counter: getRejectedCountOther,
            selected: query.type === 'rejected' && query.subtype === 'all'
          },
          {
            title: 'Mine',
            params: {chatId: ''},
            query: {type: 'rejected', subtype: 'personal'},
            type: 'rejected',
            name: 'rejected',
            counter: getRejectedCountPersonal,
            innerBadge: false,
            selected: query.type === 'rejected' && query.subtype === 'personal'
          }
        ]
      }
    }
    return {
      title: 'Rejected',
      type: 'rejected',
      name: 'rejected',
      params: {chatId: ''},
      query: {type: 'rejected', subtype: 'personal'},
      counter: getRejectedCount,
      innerBadge: false,
      selected: query.type === 'rejected' && query.subtype === 'personal'
    }
  }

  get navItemAppoints() {
    const {appointsCount, appointsCountPersonal, appointsCountBusiness} = requestsStore;
    const query = this.$route.query;
    if (applicationStore.isAdmin) {
      return {
        title: 'Appointments',
        type: 'appointments',
        name: 'appointments',
        counter: appointsCount,
        group: true,
        expanded: true,
        children: [
          {
            title: 'All',
            params: {appointId: ''},
            query: {subtype: 'all'},
            type: 'appointments',
            name: 'appointments',
            counter: appointsCountBusiness,
            selected: this.$route.name === 'appointments' && query?.subtype === 'all'
          },
          {
            title: 'Mine',
            params: {appointId: ''},
            query: {subtype: 'personal'},
            type: 'appointments',
            name: 'appointments',
            counter: appointsCountPersonal,
            selected: this.$route.name === 'appointments' && query?.subtype === 'personal'
          },
          {
            title: 'Assistance',
            params: {appointId: ''},
            query: {subtype: 'assistance'},
            type: 'appointments',
            name: 'appointments',
            counter: 0,
            selected: this.$route.name === 'appointments' && query?.subtype === 'assistance'
          }
        ]
      }
    }
    return {
      title: 'Appointments',
      type: 'appointments',
      name: 'appointments',
      params: {appointId: ''},
      query: {subtype: 'personal'},
      counter: appointsCount,
      selected: this.$route.name === 'appointments' && query?.subtype === 'personal'
    }
  }

  get navItems() {
    const {navItemInbox, navItemRejected, navItemAppoints} = this;
    return [
      navItemInbox,
      navItemRejected,
      navItemAppoints
    ]
  }

  async onNavItemSelected(navItem) {
    if (navItem.name === 'appointments') {
      navItem.params.appointId = await requestsStore.findAppointmentIdForType(navItem.query.subtype)
      if (!navItem.params.appointId) {
        navItem.params.appointId = 'empty'
      }
    }
    if (navItem.name === 'inbox') {
      const chatId = await requestsStore.findRequestIdForType({
        type: navItem.query.type,
        subtype: navItem.query.subtype
      })
      if (chatId) {
        await this.$router.push({name: `${navItem.name}-request`, params: {chatId}, query: navItem.query || {}});
        return
      }
    }
    await this.$router.push({name: navItem.name, params: navItem.params, query: navItem.query || {}});
  }

  created() {
    this.selectPage(2);
  }

  async beforeRouteEnter(to, from, next) {
    console.log(`Requests :: beforeRouteEnter, chatId=${to.params.chatId} ${to.name}`);
    const chatId = to.params.chatId;
    const appointId = to.params.appointId
    const type = to.query.type;
    const subtype = to.query.subtype;
    if (!chatId && !appointId) {
      const id = await requestsStore.findRequestIdForType({type, subtype})
      if (id && await requestsStore.checkRequestById(id)) {
        next({name: 'inbox-request', params: {chatId: id}, query: to.query});
      } else if(to.name === 'inbox') {
        next()
      } else {
        next({name: 'inbox', query: to.query})
      }
    } else if(chatId) {
      if (await requestsStore.checkRequestById(chatId)) {
        next()
      } else if(to.name === 'inbox-request'){
        next({name: 'inbox', query: to.query})
      } else {
        next({name: 'rejected', query: to.query})
      }
    } else {
      next()
    }
  }

  beforeRouteLeave(to, from, next) {
    requestsStore.lastRequestRoute(from.fullPath)
    next()
  }
}
